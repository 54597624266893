export const activeBreaks = {
  "Butt Kickers": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825211684/rendition/720p/file.mp4?loc=external&signature=16826f120e3c1faccf00254d9781f20f4066a74870badda973d9ad835ebfb1df",
    "en-usName": "Butt Kickers",
    esName: "Talones Atrás",
  },
  "Front Lunges": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272369/rendition/720p/file.mp4?loc=external&signature=735cd79b46544b73710dc822fcc418b2308769b5d446485ad3670d2b2bcb0701",
    "en-usName": "Front Lunges",
    esName: "Desplantes Frontales",
  },
  Pushups: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272313/rendition/720p/file.mp4?loc=external&signature=645a5c51bbe68348c354dc5fc5884d6570da0d1f28434fc6479a9a7fa93f4317",
    "en-usName": "Push-Ups",
    esName: "Push-Ups",
  },
  "Jumping Jacks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825212032/rendition/720p/file.mp4?loc=external&signature=117bcb62a47b4ff0a43e436115be16dafbc44020a512ad8c7e055b4ae80f516d",
    "en-usName": "Jumping Jacks",
    esName: "Jumping Jacks",
  },
  "Side Lunges": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272292/rendition/720p/file.mp4?loc=external&signature=bf37e3ba0082bb270c5c6c2015370d621fe1bba435055eafb7982f2251741807",
    "en-usName": "Side Lunges",
    esName: "Sentadilla Lateral",
  },
  "High Plank Shoulder Taps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272405/rendition/720p/file.mp4?loc=external&signature=15ba699e87315fbb0ee9e9f414778e7e6f59ed188ef6df1d7a9320374f8a1e92",
    "en-usName": "High Plank Shoulder Taps",
    esName: "Plancha Alta con Toque de Hombros",
  },
  "High Knees": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825204207/rendition/720p/file.mp4?loc=external&signature=b6284b26cc4de1d8f9415e022bc18c7ad57de621161b88958c0af5abdf66cf7b",
    "en-usName": "High Knees",
    esName: "Rodillas Altas",
  },
  Squats: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825201367/rendition/720p/file.mp4?loc=external&signature=c67484029aee80879f5f24fd0c7133bfe53c19e9b0264cc286dcfc5b10f366d8",
    "en-usName": "Squats",
    esName: "Sentadilla",
  },
  Crunches: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825229519/rendition/720p/file.mp4?loc=external&signature=aebc1efffa7fbc03d30cb29f1a59f06ac73cabe925411e1686e3cd146581172a",
    "en-usName": "Crunches",
    esName: "Abdominales Crunches",
  },
  "Speed Skaters": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825227826/rendition/720p/file.mp4?loc=external&signature=739cdce484a3532e0ea8e0b21a2e49361ac205aace7b828b79163cded4214d70",
    "en-usName": "Speed Skaters",
    esName: "Patinadores Rápidos",
  },
  "Squat Kicks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825222653/rendition/720p/file.mp4?loc=external&signature=b849a20031f8cb1d3ef8210b4ebc88a2eda469a00b3a35ac80c865e178c49cb2",
    "en-usName": "Squat Kicks",
    esName: "Sentadilla con Patada Frontal",
  },
  Burpees: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272465/rendition/720p/file.mp4?loc=external&signature=6ecabd399c37ed016c5af4b63926671c096614eb1246e19eacd0c481b86cf738",
    "en-usName": "Burpees",
    esName: "Burpees",
  },
  "Plank & Hold": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825201224/rendition/720p/file.mp4?loc=external&signature=c32732f71bbe5eeaf2023d9ebe48127f9b965926dc907a6e13222b74a9e17ab3",
    "en-usName": "Plank & Hold",
    esName: "Plancha y Sostengo",
  },
  Surrenders: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272207/rendition/720p/file.mp4?loc=external&signature=93a2acbe0cd15d39c030d0fac8db6f86e0f2fa3a40ac534b066f3b66ce2fe4e8",
    "en-usName": "Surrenders",
    esName: "Hincarse y Levantarse",
  },
  "Mountain Climbers": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825203978/rendition/720p/file.mp4?loc=external&signature=53841e5d50bc7f29047c3ed23115d258e84a29a0d2221af4c1783a3c896a682e",
    "en-usName": "Mountain Climbers",
    esName: "Escaladores",
  },
  "Bicycle Crunch": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825211969/rendition/720p/file.mp4?loc=external&signature=1e64378e4e01b64883042a41d713a14b76cc89c3401566cdf9706182b9a68d43",
    "en-usName": "Bicycle Crunch",
    esName: "Abdominales Bicicleta",
  },
  "Squat Hold": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825203480/rendition/720p/file.mp4?loc=external&signature=0fa6e545407689df94d06f23d3a354fc3175467d39aef0b4a8b4694faa7ed680",
    "en-usName": "Squat Hold",
    esName: "Sentadilla isométrica contra Pared",
  },
  "Front to Back Hops": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825204365/rendition/720p/file.mp4?loc=external&signature=25b587698d5b91fd479092f92133a865daa3237c53b6aeb2227473fec6a65cc8",
    "en-usName": "Front-to-Back Hops",
    esName: "Saltos de Adelante y hacia Atrás",
  },
  "Squat Jumps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825203414/rendition/720p/file.mp4?loc=external&signature=8a5e3bc1bd71d423cc09aa65c57e9efb53e3b28508af01abee1678e5c77fa319",
    "en-usName": "Squat Jumps",
    esName: "Sentadilla con Salto",
  },
  "Half Burpees": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825204290/rendition/720p/file.mp4?loc=external&signature=03517af23a580a6ff3252478d1e387013c182d4e4442482d8dbd21a38b984ecb",
    "en-usName": "Half Burpees",
    esName: "Medio Burpees",
  },
  "Reverse Lunges": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825222545/rendition/720p/file.mp4?loc=external&signature=f6eac63f7f6d71b30e761027a892a4242eb3d064a7517ffce39386e1fbfc99b7",
    "en-usName": "Reverse Lunges",
    esName: "Desplantes hacia atrás ",
  },
  "Push-Up & Punch": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272348/rendition/720p/file.mp4?loc=external&signature=88b2fa2b85ea51ee53639c64ba244c5cbdff1a458f9a018e7fbca8b085262ecf",
    "en-usName": "Push-Up & Punch",
    esName: "Push-Up & Golpe al Aire",
  },
  "Ali Shuffle": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825222590/rendition/720p/file.mp4?loc=external&signature=94c5ad1edb331be4148f0892fd98b8fcfb28ef48497678b77d13c22e39ad2940",
    "en-usName": "Ali Shuffle",
    esName: "Ali Shuffle",
  },
  "High Plank Jacks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825204153/rendition/720p/file.mp4?loc=external&signature=766b6e08da29f2e8548c6650e910920e8ab06a77e3ff82b6d6d21dbaecb9d9bc",
    "en-usName": "High Plank Jacks",
    esName: "Plancha Alta con Jumping Jacks",
  },
  "Squat-to-Lunge": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272257/rendition/720p/file.mp4?loc=external&signature=0c8842a0227d7bf6a5b79b22a130b0a06bd4a8393574983dcd4ca32b90ca2076",
    "en-usName": "Squat-to-Lunge",
    esName: "Sentadilla y Desplante Alternando",
  },
  "Criss Cross Jacks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/826272432/rendition/720p/file.mp4?loc=external&signature=cf487746d553a80d5d78ddd4368414b5e1ec344bd70a50d5199e01525834dfc8",
    "en-usName": "Criss Cross Jacks",
    esName: "Jumping Jacks cruzando piernas",
  },
  "Flutter Kicks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825204447/rendition/720p/file.mp4?loc=external&signature=a8dd90567df7811893961493eb36c0d7745511478dbc225ed526b96f55ee3f34",
    "en-usName": "Flutter Kicks",
    esName: "Patadas de Nadador",
  },
  "High Plank Punch": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492199/rendition/720p/file.mp4?loc=external&signature=0ba0c128d86cf8895ea92442d39b7b6fc1b195883265db70ae45f9933bf36116",
    "en-usName": "High Plank Punch",
    esName: "",
  },
  "Low Plank Hip Dips": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492169/rendition/720p/file.mp4?loc=external&signature=af5d9e40a7fd97a9a981ff8a1fc63b4cbda6ad4053dff010af341f01dd420c0e",
    "en-usName": "Low Plank Hip Dips",
    esName: "",
  },
  "High To Low Plank": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492008/rendition/720p/file.mp4?loc=external&signature=c03071b086246af961c2d529c82e607add08a840e5772fcb83618552b6314838",
    "en-usName": "High To Low Plank",
    esName: "",
  },
  "Low Plank Heel Kicks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492137/rendition/720p/file.mp4?loc=external&signature=949ec415b547bbb172a9def6eeab8dd35dcc2d6edd34488c77c5047c5656283f",
    "en-usName": "Low Plank Heel Kicks",
    esName: "",
  },
  "Left Side Plank Hip Raise": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492052/rendition/720p/file.mp4?loc=external&signature=6a91290f7ddfe3b26c4c76f84d9c1989f7a91879df355b49cb1cc5e073455270",
    "en-usName": "Left Side Plank Hip Raise",
    esName: "",
  },
  "Right Side Plank Hip Raise": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492237/rendition/720p/file.mp4?loc=external&signature=287c28ef6ea0698c961558ccb78043c9daa2be8e576d560f0fdde324ee9fa24d",
    "en-usName": "Right Side Plank Hip Raise",
    esName: "",
  },
  "Low Plank": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/825201224/rendition/720p/file.mp4?loc=external&signature=c32732f71bbe5eeaf2023d9ebe48127f9b965926dc907a6e13222b74a9e17ab3",
    "en-usName": "Low Plank",
    esName: "",
  },
  "High Plank Knee To Shoulder": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859491976/rendition/720p/file.mp4?loc=external&signature=e8de4064ee3f01650331da8a3f84f3f0d077c695888fc11c78ff112791355187",
    "en-usName": "High Plank Knee To Shoulder",
    esName: "",
  },
  "Left Side Plank": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492118/rendition/720p/file.mp4?loc=external&signature=c1072613722b24ea31ee9959a521b7af9b8dfc8b58413154d1269763caa9f244",
    "en-usName": "Left Side Plank",
    esName: "",
  },
  "Right Side Plank": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859492279/rendition/720p/file.mp4?loc=external&signature=cc72fcb926c8e0fb3ee109abe5073b9e1b94547790d7e87615905050756f8b6f",
    "en-usName": "Right Side Plank",
    esName: "",
  },
  "High Plank Bird Dog": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/859491911/rendition/720p/file.mp4?loc=external&signature=016f780faabd20b193f47be9e84417bee31d2e27b0118d34b8f691bf540579d3",
    "en-usName": "High Plank Bird Dog",
    esName: "",
  },
  "V-Sit Punches": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1037449265/rendition/720p/file.mp4?loc=external&signature=4174146cd0f2215646ead6a063ab898675c93500071895a82b3c32ec4070f468",
    "en-usName": "V-Sit Punches",
    esName: "",
  },
  "Pushup + Shoulder Tap": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598745/rendition/720p/file.mp4?loc=external&signature=25863320650149076fda1ad8f95f9667ed675554852d931afd8d59a0ce48dce5",
    "en-usName": "Push-Up + Shoulder Tap",
    esName: "",
  },
  "Squat Switch Stances": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598864/rendition/720p/file.mp4?loc=external&signature=d5448aa34d4492e6ba321735d7b1e6bf88fa8811c2efb47549002f8b22902efb",
    "en-usName": "Squat Switch Stances",
    esName: "",
  },
  "High Plank Heel Kicks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598637/rendition/720p/file.mp4?loc=external&signature=bb4bfbe3e723b0ebad49dfe88d68b5988e750aab2b9a4e5c6c900074bb1f395a",
    "en-usName": "High Plank Heel Kicks",
    esName: "",
  },
  "Squat Hold + Punches": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598837/rendition/720p/file.mp4?loc=external&signature=7f8a0b8e5f8f1b0068bf6ab24a7382dff2886c8fd9883a20200971296da60185",
    "en-usName": "Squat Hold + Punches",
    esName: "",
  },
  "Square Hops": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598820/rendition/720p/file.mp4?loc=external&signature=cee67cbda0a79a478e3d98c62e6dde3489eb8d70266edad02f1c5c556aaa8346",
    "en-usName": "Square Hops",
    esName: "",
  },
  "Sit-Ups": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1037449049/rendition/720p/file.mp4?loc=external&signature=046937da83f08705d64f698919829b3923c02ca60847c63155f196f110545685",
    "en-usName": "Sit-Ups",
    esName: "",
  },
  "Lunge Jumps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598672/rendition/720p/file.mp4?loc=external&signature=39a86db5535d55849f4d53babe242d68e237310cbdf8907ebadd8284559a838f",
    "en-usName": "Lunge Jumps",
    esName: "",
  },
  "Quick Feet": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598763/rendition/720p/file.mp4?loc=external&signature=2e24266db367f83e80636cf45e291c676b8e56d265c1b76454c4aaf72222c3a0",
    "en-usName": "Quick Feet",
    esName: "",
  },
  "Squat + Knee Strike": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598846/rendition/720p/file.mp4?loc=external&signature=bbe3fa610199f79c99c99a1e73befe3a714eadfcc5ca614ff6dae0f17325c9e3",
    "en-usName": "Squat + Knee Strike",
    esName: "",
  },
  "Double Punch Jacks": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598574/rendition/720p/file.mp4?loc=external&signature=03207d32ee9f72a20725fd723b6fb521356706c202de8944428399cdebd7ff99",
    "en-usName": "Double Punch Jacks",
    esName: "",
  },
  "Pulsing Squats": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598725/rendition/720p/file.mp4?loc=external&signature=6e4d97aa2116343516093339692b1602a862ecdb911e0ff123f08f82fa7a6c63",
    "en-usName": "Pulsing Squats",
    esName: "",
  },
  "V-Sit Twists": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598910/rendition/720p/file.mp4?loc=external&signature=2bfdceecc992eb6e8fa420c40be583b0d3a4e054a470758103d13d6afad6fe2a",
    "en-usName": "V-Sit Twists",
    esName: "",
  },
  "Drop Squats": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598592/rendition/720p/file.mp4?loc=external&signature=250d3b8d1f5d8eea5a2c7c0cb0e75820d267e6e3b640f37b38ea3e057d3c4992",
    "en-usName": "Drop Squats",
    esName: "",
  },
  "High Plank + Punch": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1037449019/rendition/720p/file.mp4?loc=external&signature=8434b418bcec969e8812a0182cc0ab99e1fad40e3835b9b54ca38c13b499d4f2",
    "en-usName": "High Plank + Punch",
    esName: "",
  },
  Heismans: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598612/rendition/720p/file.mp4?loc=external&signature=b3a5befa7e1eb11262a54e9733e6bf0cc4ddece5e6378be73184db06f8940b05",
    "en-usName": "Heismans",
    esName: "",
  },
  "Cross-Body Mountain Climbers": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246336/rendition/720p/file.mp4?loc=external&signature=3d587e8948dc4a59a75dc77d950a33cd03916f26ae38bc6bf9d17cee7cdb5fca",
    "en-usName": "Cross-Body Mountain Climbers",
    esName: "",
  },
  Candlestick: {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246302/rendition/720p/file.mp4?loc=external&signature=65f8cefcd5bf4b8c64fa16a426a3b3bea346e8a749fdab54f93d38112f3683f3",
    "en-usName": "Candlestick",
    esName: "",
  },
  "Double Crunches": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246365/rendition/720p/file.mp4?loc=external&signature=9e3c6f31756665f87b51dd106af72c95ede9bb5e7e6cd9daa85b228b41f0f9c6",
    "en-usName": "Double Crunches",
    esName: "",
  },
  "Low Plank Body Saw": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246438/rendition/720p/file.mp4?loc=external&signature=f76af281ded31e8ffd498949a72f89e2dce10388161ab02e2ddaff1a004a2104",
    "en-usName": "Low Plank Body Saw",
    esName: "",
  },
  "V-Sit In & Outs": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246596/rendition/720p/file.mp4?loc=external&signature=479a90a93ec7e70f4eb1c1ebf2423de99206fcbc22201e5b1eb4c362396abdf1",
    "en-usName": "V-Sit In & Outs",
    esName: "",
  },
  "Pike Crunches": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246538/rendition/720p/file.mp4?loc=external&signature=77a299dca738448d5ce044c8757e92e837884ba4897e1347d80dc49cf3793ef7",
    "en-usName": "Pike Crunches",
    esName: "",
  },
  "Low Plank Knee Taps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246517/rendition/720p/file.mp4?loc=external&signature=226542e2a24539ee1730ff35fc41aeb7afe5c468bffe9403c5747316754bc75b",
    "en-usName": "Low Plank Knee Taps",
    esName: "",
  },
  "Ali Crunches": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1037449243/rendition/720p/file.mp4?loc=external&signature=05430bed3d6e6e2e18071fbf9cd814d97c33048fe65e4148fddb80adc3405660",
    "en-usName": "Ali Crunches",
    esName: "",
  },
  "Heel Grabbers": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246385/rendition/720p/file.mp4?loc=external&signature=8dd96634d0c4ecddd785f04e7929e077c80a50d90213cbe148d02e9404a89826",
    "en-usName": "Heel Grabbers",
    esName: "",
  },
  "X-Ups": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035246614/rendition/720p/file.mp4?loc=external&signature=538c80cf9c62f70a16ff99dd109387caedd28b78f3462b08a48084b389c57960",
    "en-usName": "X-Ups",
    esName: "",
  },
  "Pulsing Sumo Squats": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271494/rendition/720p/file.mp4?loc=external&signature=26783cb8344762f4ebb0fe05505d8522b583aae3e4cc096b77bd859385be76a8",
    "en-usName": "Pulsing Sumo Squats",
    esName: "",
  },
  "Wide-to-Narrow Squat Hops": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271651/rendition/720p/file.mp4?loc=external&signature=19d563456d1a090839cf1e57b916fdfbfbd530030b37f91ce2564b39871272c6",
    "en-usName": "Wide-to-Narrow Squat Hops",
    esName: "",
  },
  "Front-to-Back Lunges": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271381/rendition/720p/file.mp4?loc=external&signature=9237f1b488107661c3f0f37e869e1af15795a6188cde3bb0b90b17277d33bf51",
    "en-usName": "Front-to-Back Lunges",
    esName: "",
  },
  "Calf Hops": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271326/rendition/720p/file.mp4?loc=external&signature=b60d44f78fafa014840a30bc29ee7e6638366c37e9a5eaa98f428df71b33068f",
    "en-usName": "Calf Hops",
    esName: "",
  },
  "Lateral Squat Jumps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271434/rendition/720p/file.mp4?loc=external&signature=eed926969ec1be8dbb13cfef8b5c082fbb6ab6dd6b52f6b974560cf686743b23",
    "en-usName": "Lateral Squat Jumps",
    esName: "",
  },
  "Squat Hold + Side Toe Taps": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271573/rendition/720p/file.mp4?loc=external&signature=86c5b2ee854629f89c0308865ad1e11f1c441c6c803fee1ad7aa93c0ce197ec4",
    "en-usName": "Squat Hold + Side Toe Taps",
    esName: "",
  },
  "Side Stepping Squats": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1035271550/rendition/720p/file.mp4?loc=external&signature=2e610db22f32ed664b5a4bdcd8a7b77cc3451abab70ccdd931a98cc10c2af655",
    "en-usName": "Side Stepping Squats",
    esName: "",
  },
  "Lunge & Punch": {
    videoLink:
      "https://player.vimeo.com/progressive_redirect/playback/1033598689/rendition/720p/file.mp4?loc=external&signature=2cb9d5a7cbb82f9d8ea51bdc52929f26b93992f2bd2511e9016b93af632c987b",
    "en-usName": "Lunge + Punch",
    esName: "",
  },
};

export const findExercise = (exercise, type) => {
  return activeBreaks[exercise] && activeBreaks[exercise][type]
    ? activeBreaks[exercise][type]
    : "";
};
